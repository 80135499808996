@import "../../styles/base";

.forgot-password {
    padding: 1.25rem;
    background-color: #fff !important;
    &__heading {
        color: $color--neutral-1000;
    }

    &__success-message {
        color: $color--neutral-1000;
    }
    &__input {
        .MuiInputBase-input {
            border: 1px solid $color--neutral-500;
        }
    }
    &__submit {
        &.MuiButtonBase-root{
            background-color: $brand-color;
            color:#fff;
        }
    }
}
