@import "colors";
@import "mixins";
@import url("https://fonts.googleapis.com/css?family=Hind&display=swap");

body {
  font-family: "Hind", sans-serif;
  background-color: $color--neutral-800 !important;
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @include x-small-screen() {
    -webkit-user-drag: none;
    user-select: none;
  }
}

a:hover,
a:focus {
  color: none;
  text-decoration: none;
}

.App {
  text-align: center;
  position: relative;
  min-height: 100vh;
  height: 100%;
}

ul > li{
    list-style: none;
}

.page-content {
  min-height: 100vh;
  padding: 2rem 0;
}


.begindrawing {
  font-size: 26px;
  position: relative;
  bottom: 70px;
  width: auto;
  height: 120px;
  margin: auto;
  margin-bottom: -100px;
  display: block;
  color: $color--neutral-100;
}

//autoComplete input textarea select styling

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border-bottom: 1px solid $brand-color;
  -webkit-text-fill-color: $brand-color;
  transition: background-color 5000s ease-in-out 0s;
}