@import "../../styles/base";

.profile {
    cursor: pointer;
    input {
        color: #fff;
    }
    fieldset.MuiOutlinedInput-notchedOutline {
        border-color: $color--neutral-400;
    }
    &:hover {
        fieldset.MuiOutlinedInput-notchedOutline {
            border-color: #fff;
        }
    }
    .MuiFormControl-root[aria-expanded="true"] + .autocomplete-dropdown-container {
        border: 1px solid white;
        padding: 10px;
        div {
            &:hover {
                background-color: $brand-color;
            }
        }
    }

    &__userInfo{
        &-location-search-input{
            width: 90%;
            max-width: 400px;
            color:"#000"
        }
    }
}
