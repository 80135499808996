@import "../../styles/base";

.container__userProfile {
  display: flex;
}
.userProfile {
  text-align: left;
  padding: 1rem;
  color: #fff;
  @media (min-width: $breakpoint--small) {
    min-width: 300px;
    max-width: 600px;
    margin: 2rem auto;
  }
}
