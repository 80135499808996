@import "../../styles/base";

.croqee-video-section {
  min-height: calc(var(--vh, 1vh) * 90);
  display: flex;
  flex-direction: column;
  justify-content: center;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: -webkit-gradient(
      180deg,
      rgba(0, 0, 0, 0.9) 7%,
      rgba(0, 0, 0, 0.4) 40%,
      rgba(0, 0, 0, 0.58) 79%,
      #000 100%
    );
    background-image: -o-linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.9) 7%,
      rgba(0, 0, 0, 0.4) 40%,
      rgba(0, 0, 0, 0.58) 79%,
      #000 100%
    );
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.9) 7%,
      rgba(0, 0, 0, 0.4) 40%,
      rgba(0, 0, 0, 0.58) 79%,
      #000 100%
    );
    z-index: -1;
  }

  &__title {
    text-align: left;
    font-size: 1.3em;
    clear: both;
    color: white;
    font-weight: 400;
    margin-left: 2em;

    &-main {
      margin-bottom: 0;
      line-height: 50px;
    }
    &-caption {
      color: white;
      margin-top: 1em;
      margin-bottom: 1em;
      font-size: 1em;
    }
    @media (max-width: 1100px) {
      font-size: 1em;
      text-align: center;
    }
  }
  &__video {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;
    opacity: 0.5;
  }
}
