@import "../../styles/base";

.auth_form {
  display: flex;
  flex-direction: column;
  .auth_form-input {
    margin-bottom: 20px;
  }
}
a {
  color: $brand-color;
}
