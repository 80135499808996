@import "../../styles/base";

.hand-side {
    top: 6px;
    clear: both;
    color: #5d5e5e;
    font-size: 18px;
    position: absolute;
    z-index: 200;
    margin: auto;
    left: 0;
    right: 0;
    background: #6a6a6a;
    width: 210px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3000;
    &--fade-in {
        @include fadeIn();
    }
    &--fade-out {
        @include fadeOut();
    }
}
.btn {
    display: inline-block;
    padding: 10px;
    position: relative;
    text-align: center;
    transition: background 600ms ease, color 600ms ease;
}

input[type="radio"].toggle {
    display: none;
    & + label {
        cursor: pointer;
        min-width: 80px;
        color: $color--neutral-100;
        &:hover {
            background: none;
            color: #fff;
        }
        &:after {
            content: "";
            height: 100%;
            position: absolute;
            top: 0;
            transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
            width: 100%;
            z-index: -1;
        }
    }
    &.toggle-left + label {
        border-right: 0;
        &:after {
            left: 100%;
        }
    }
    &.toggle-right + label {
        margin-left: -5px;
        &:after {
            left: -100%;
        }
    }
    &:checked + label {
        cursor: default;
        color: #000;
        transition: color 200ms;
        user-select: none;
        &:after {
            left: 0;
        }
    }
}
