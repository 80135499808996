@import "../../../styles/base";

$modelSelectorSize: 77px;

.model-properties-selector {
	position: relative;
	width: $modelSelectorSize;
	height: auto;
	right: 127px;
	margin-bottom: -97px;
	padding-left: 11px;
	padding-top: 13px;
	z-index: 2;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	left: 0;
	&--hide {
		display: none;
	}
	span {
		width: 70px;
		height: 70px;
		display: inline-block;
		margin-bottom: 10px;
		position: relative;
		right: 6px;
		&:hover {
			opacity: 1;
		}
	}
	&__outline {
		background-image: url("https://media.discordapp.net/attachments/988891650174615572/1017142893355487297/outline.png");
		background-size: 216px;
		background-position: -68px -37px;
		border: 2px solid  $brand-color;
		opacity: 1;

		&--inactive {
			border: 2px solid white ;
			opacity: 0.5;
		}
	}
	// &__model {
	// 	background-image: url("https://cdn.discordapp.com/attachments/535653157791989804/807002946927067196/female1.png");
	// 	background-size: 167px;
	// 	background-position: -46px -29px;
	// 	opacity: 0.5;
	// 	border: 2px solid white;
	// 	&--active {
	// 		border: 2px solid $brand-color;
	// 		opacity: 1;
	// 	}
	// }
}
