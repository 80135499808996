@import "../../styles/base";

.loader {
  display: block;
  width: 36px;
  height: 36px;
  position: relative;
  border: 4px solid $brand-color;
  top: 50%;
  -webkit-animation: loader 2s infinite ease;
  animation: loader 2s infinite ease;
  margin: -22px auto;
  z-index: 1000;
  -webkit-user-drag: none;
  user-select: none;
  &__inner {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: $brand-color;
    animation: loader-inner 1s infinite ease-in;
  }
}
.loader-canvas-blocker {
  position: absolute;
  -webkit-user-drag: none;
  user-select: none;
  width: 100%;
  height: 100%;
  background-color: $color--neutral-900;
  opacity: 0.6;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
