
@mixin fadeOut() {
	animation: fadeout 0.3s ease-in 0s 1 forwards;
	@keyframes fadeout {
		0% {
			visibility: visible;
			opacity: 1;
		}
		100% {
			visibility: hidden;
			opacity: 0;
		}
	}
}
@mixin fadeIn() {
	animation: fadein 0.3s ease-in 0s 1 forwards;
	@keyframes fadein {
		0% {
			visibility: hidden;
			opacity: 0;
		}
		100% {
			visibility: visible;
			opacity: 1;
		}
	}
}


@mixin ipad-pro {
	@media (max-width: 900px) {
		@content;
	}
}

@mixin x-small-screen {
	@media (max-width: 800px) {
		@content;
	}
}
@mixin small-screen {
	@media (max-width: 1320px) {
		@content;
	}
}
@mixin medium-small-screen {
	@media (max-width: 1365px) {
		@content;
	}
}
@mixin x-medium-small-screen {
	@media (max-width: 1400px) {
		@content;
	}
}
@mixin xx-medium-small-screen {
	@media (max-width: 1450px) {
		@content;
	}
}
@mixin medium-screen {
	@media (max-width: 1500px) {
		@content;
	}
}
@mixin x-medium-screen {
	@media (max-width: 1540px) {
		@content;
	}
}
@mixin xx-medium-screen {
	@media (max-width: 1580px) {
		@content;
	}
}
@mixin medium-big-screen {
	@media (max-width: 1620px) {
		@content;
	}
}
@mixin x-medium-big-screen {
	@media (max-width: 1660px) {
		@content;
	}
}
@mixin xx-medium-big-screen {
	@media (max-width: 1700px) {
		@content;
	}
}
@mixin big-screen {
	@media (max-width: 1800px) {
		@content;
	}
}


@mixin canvas-and-model-xx-medium-big {
    width: 780px;
    height: 585px;
}
@mixin canvas-and-model-x-medium-big {
    width: 760px;
    height: 570px;
}
@mixin canvas-and-model-medium-big {
    width: 740px;
    height: 555px;
}
@mixin canvas-and-model-xx-medium {
    width: 720px;
    height: 540px;
}
@mixin canvas-and-model-x-medium {
    width: 700px;
    height: 525px;
}
@mixin canvas-and-model-medium {
    width: 680px;
    height: 510px;
}
@mixin canvas-and-xx-medium-small{
    width: 660px;
    height: 495px;
}
@mixin canvas-and-x-medium-small{
    width: 640px;
    height: 480px;
}
@mixin canvas-and-medium-small{
    width: 620px;
    height: 465px;
}
@mixin canvas-and-small{
    width: 600px;
    height: 450px;
}


//account/profile form
@mixin profile-form {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
  }
  @mixin profile-form-input {
	border-top-style: none;
	border-right-style: none;
	border-left-style: none;
	border-bottom-style: outset;
	background-color: transparent;
	outline: none;
	color: #b8b8b8;
	font-size: 1rem;
	flex-basis: 60%;
  }
  @mixin profile-form-input-focus {
	transition: border-bottom 0.2s ease-in;
	border-bottom: 2px solid $brand-color;
  }

  
