@import "../../styles/base";

// $Var
$bg-color: $color--neutral-400;
$color: $brand-color;

// $Mixin
@mixin size($width , $height: $width ) {
	width: $width;
	height: $height;
}

@mixin absPosition ($top: auto, $right: auto, $bottom: auto, $left: auto) {
	position: absolute;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

@mixin animation($animation...) {
	-webkit-animation: $animation;
	animation: $animation;
}

.userpendingloader {
	user-select: none;
	display: flex;
	justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 0;
	&__caption {
		font-size: 26px;
		margin-left: 20px;
		color:#fff;
	}
}
.userpendingloader .loader {
	margin: 0;
}


