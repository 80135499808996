@import "../../styles/base";

.drawing-environment{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    touch-action: none;
    &--column{
        flex-direction: column;
    }
    &.model_left_hand{
        flex-direction: row-reverse;
    }
}