@import "../../../styles/base";

.drawing-model {
	position: relative;
	background-color: $color--neutral-800;
	display: flex;
    flex-direction: row;
	justify-content: center;
	z-index: 3;
	overflow: hidden;
}
