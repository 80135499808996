@import "../../styles/base";

.signup {
  text-align: left;
  padding: 20px;
  color: #fff;
  @media (min-width: $breakpoint--small) {
    min-width: 300px;
    max-width: 600px;
    margin: 2rem auto;
  }
  &__card {
    padding: 20px;
  }
  a {
    color: $brand-color;
  }
}
