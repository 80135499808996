@import "../../styles/base";

.competes {
	&__wrapper {
		margin: 0 30px;
		margin-top: 50px;
		text-align: center;
		&-header {
			display: flex;
			justify-content: center;
			margin-bottom: 50px;
			color:$color--neutral-100;
			font-weight: 500;
			font-size:28px;
		}
	}
}
