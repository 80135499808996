@import "../../styles/base";

.account-sideNav {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 10%;
  @media (max-width: $breakpoint--large) {
    margin-left: 30px;
  }
  @media (min-width: $breakpoint--small) {
    flex: 0 0 22%;
  }
  a {
    height: 40px;
  }
  &__inActive {
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    color: white;
  }
  &__active {
    color: $brand-color;
  }
}
