@import "../../../styles/base";

$showModelsDuration: 0.7s;
$modelSelectorSize: 77px;
@keyframes move-right {
	0% {
		right: 127px;
	}
	100% {
		right: 0;
	}
}
@keyframes move-right-from-right {
	0% {
		left: 0;
	}
	100% {
		left: 127px;
	}
}
@keyframes move-left {
	0% {
		right: 0;
	}
	100% {
		right: 127px;
	}
}
@keyframes move-left-from-right {
	0% {
		left: 127px;
	}
	100% {
		left: 0;
	}
}

.model-selector {
	position: absolute;
	width: $modelSelectorSize;
	height: auto;
	right: 127px;
	margin-bottom: -249px;
	padding-left: 11px;
	padding-top: 13px;
	z-index: 2;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	left: 0;
	&--hide {
		display: none;
	}
	span {
		width: 70px;
		height: 70px;
		display: inline-block;
		margin-bottom: 10px;
		position: relative;
		right: 6px;
		&:hover {
			opacity: 1;
		}
	}
	&__still-life-1 {
		background-image: url("https://cdn.discordapp.com/attachments/535653157791989804/807002910184439848/geometrical5.png");
		background-size: 106px;
		background-position: -18px -17px;
		opacity: 0.5;
		border: 2px solid white;
		&--active {
			border: 2px solid #ff5200;
			opacity: 1;
		}
	}
	&__anatomy-1 {
		background-image: url("https://cdn.discordapp.com/attachments/535653157791989804/807002946927067196/female1.png");
		background-size: 167px;
		background-position: -46px -29px;
		opacity: 0.5;
		border: 2px solid white;
		&--active {
			border: 2px solid $brand-color;
			opacity: 1;
		}
	}
}
