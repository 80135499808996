@import "../../styles/base";
$button-size: 28px;

.leaderboard {
    padding-top: 52px;
    max-width: 1000px;
    width: 80%;
    margin: auto;
    color: #fff;
    min-height: 700px;
    &__title {
        font-weight: 500;
        padding-bottom: 50px;
        font-size: 41px;
        text-align: center;
    }

    &__rank-desc {
        font-weight: 500;
        padding-top: 35px;
        padding-bottom: 50px;
        font-size: 26px;
        text-align: center;
    }

    &__overall {
        color: $color--neutral-100;
        display: flex;
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    &__overall-percentage {
        margin-bottom: 60px;
        &-circle {
            width: 120px;
            height: 120px;
            margin: auto;
            background-color: $color--neutral-100;
            border-radius: 50%;
            color: $color--neutral-800;
            &-text {
                position: relative;
                top: 30px;
                font-size: 40px;
            }
            position: relative;
        }
        &-desc {
            margin-top: 20px;
            font-size: 18px;
            position: relative;
        }
    }
    &__topic-percentage {
        color:  $color--neutral-100;
        display: inline-block;
        margin-bottom: 60px;
        margin-right: 22px;
        margin-left: 22px;
        &-circle {
            width: 120px;
            height: 120px;
            margin: auto;
            background-color: $color--neutral-400;
            border-radius: 50%;
            &-text {
                position: relative;
                top: 30px;
                font-size: 40px;
            }
        }
        &-desc {
            margin-top: 20px;
            font-size: 18px;
        }
    }
    b {
        color: $brand-color;
        font-weight: 400;
    }

    &__scores {
        display: flex;
        flex-direction: column;
        margin: auto;
        font-size: 22px;
        clear: both;
        color:  $color--neutral-100;
        &-head {
            border-bottom: 1px solid $color--neutral-100;
            height: 40px;
            background-color: $brand-color;
            color: black;
        }
        &-row {
            border-bottom: 1px solid $color--neutral-500;
            height: 50px;
            width: 100%;
            display: flex;
            &:hover {
                cursor: pointer;
                border-bottom: 1px solid $brand-color;
            }
            &-rank {
                margin-top: 8px;
                margin-right: 20px;
                width: 20px;
            }
            &-img {
                margin-right: 20px;
                margin-top: 4px;
                img {
                    width: 40px;
                    height: 40px;
                }
            }
            &-user {
                margin-top: 8px;
            }
            &-score {
                margin-top: 8px;
                align-self: end;
                margin-left: auto;
            }
            &--self {
                color: $brand-color;
                th {
                    font-weight: 500;
                }
            }
        }
        &-etc {
            background-color: $color--neutral-800;
            height: 50px;
            padding-top: 8px;
            border-bottom: 1px solid $color--neutral-500;
            font-size: 40px;
            line-height: 27px;
            user-select: none;
        }
    }
}
