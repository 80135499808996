$color--neutral-0: #f7f7f7;
$color--neutral-100: #b8b8b8;

$color--neutral-400: #5d5e5e;
$color--neutral-500: #4a4b4b;

$color--neutral-800: #131212;
$color--neutral-900: #020202;
$color--neutral-1000: #000000;

$orange: #ff3c00;
$light-blue: #06b3cf;
$brand-color: $light-blue;