@import "../../../styles/base";

.interactive-drawing-model {
	z-index: 1;
	touch-action: none;
	&--enable-control{
		cursor: pointer;
	}
	&__rotate-hint-overlay {
		position: relative;
		background-color: #fff;
		opacity: 0.75;
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		user-select: none;
		pointer-events: none;

		img {
			user-select: none;
		}
	}
}
