@import "../../styles/base";

.profile {
  text-align: left;
  padding: 1rem;
  color: #fff;
  @media (max-width: $breakpoint--large) {
    padding-left: 30px;
  }
  @media (min-width: $breakpoint--small) {
    width: 60%;
  }
  a {
    color: $brand-color;
  }
  &__ranking {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    &__highlight {
      color: $brand-color;
    }
  }
  &__img__msg {
    margin: 1rem;
    width: auto;
    display: block;
  }

  &__img-name-wrapper {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;

    &__img {
      display: flex;
      flex-direction: column;

      & > img {
        width: 125px;
        height: 125px;
        border-radius: 50%;
      }

      &-link {
        margin: 1rem 1rem 1rem;
        text-align: center;
        cursor: pointer;
        user-select: none;
      }
    }
    &__name {
      margin: 0 auto;
      width: 100%;
      padding-left: 4rem;
      > span {
        font-size: 1em;
        color: white;
      }
      & > h1 {
        margin: 0;
        font-weight: lighter;
      }
    }
  }

  &__userInfo {
    margin-top: 1rem;
    & > span {
      color: white;
      font-size: 1em;
    }

    &__container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      & > p {
        font-weight: lighter;
        font-size: 1.3em;
        margin: 0;
      }
      a {
        cursor: pointer;
        user-select: none;
      }
      &__form {
        @include profile-form();
        &__input {
          @include profile-form-input();
          &:focus {
            @include profile-form-input-focus();
          }
        }
        &__btn {
          cursor: pointer;
          flex-basis: 20%;
          color: $brand-color;
          border: none;
          font-size: 1rem;
          background-color: transparent;
          outline: none;
          text-align: right;
          &:hover {
            color: $brand-color;
          }
          &:focus {
            color: $brand-color;
          }
        }
      }
    }
  }
  &__links {
    margin-top: 2rem;
    border-top: 1px solid $color--neutral-500;
    height: 100%;
    width: 100%;
    > p {
      font-size: 1em;
      color: white;
    }
    &__data {
      color: unset;
      text-decoration: none;
      & > span {
        width: 0;
        height: 1px;
        display: block;
        border-bottom: 1px solid $brand-color;
        transition: width 0.3s ease;
        position: relative;
      }
      &:hover {
        span {
          width: 100%;
        }
      }
    }
    &__icons {
      color: #b8b8b8;
      margin: 0.5rem;
      &:hover {
        color: $brand-color;
      }
    }
  }
}

.MuiSvgIcon-root {
  color: $color--neutral-400;
}
