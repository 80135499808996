@import "../../styles/base";

.footer {
  display: block;
  position: absolute;
  width: 100%;
  text-align: left;
  height: auto;

  &-top {
    background: $color--neutral-900;
    color: $color--neutral-100;
    padding: 2.3rem 3.55555556rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 7.8125vw;
    padding-right: 7.8125vw;
    flex-direction: column;

    @media (max-width: 960px) {
      text-align: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding: 1rem;
    }
    @media only screen and (max-width: 1400px) {
      padding-left: 4.5rem;
      padding-right: 4.5rem;
    }
    @media (min-width: 650px) {
      flex-direction: row;
    }
    &--newsLetter {
      width: 30%;
      @media only screen and (min-width: 961px) {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
      }
      @media only screen and (max-width: 960px) {
        text-align: center;
        width: 100%;
        padding: 2rem 0;
      }
      @media (max-width: 650px) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
      &-form {
        overflow: hidden;
        @media (max-width: 360px) {
          overflow: hidden;
          margin: 0rem -3rem;
        }

        &-btn {
          @media only screen and (min-width: 960px) {
            display: flex;
            margin: 1rem 0.5rem 0 0.2rem;
          }
        }
      }

      &-btn {
        //  border: 1px solid #fff;
        height: 70px;
        width: 92px;
        font-weight: 800;
        text-decoration: none;
        letter-spacing: 0.09em;
        outline: none;
        user-select: none;
        display: inline-block;
        text-transform: uppercase;
        font-weight: bold;
        position: relative;
        background: linear-gradient(to right, white 50%, $brand-color 50%);
        background-size: 200% 100%;
        background-position: right bottom;
        transition: all 0.25s ease;
        cursor: pointer;
        float: right;
        &:hover {
          background-position: left bottom;
        }
        &-text {
          text-align: center;
          font-size: 1em;
          line-height: 70px;
          color: white;
          transition: all 0.3s ease;
          display: block;
          &:hover {
            color: $brand-color;
          }
        }
      }
    }
    &-contact {
      @media only screen and (max-width: 960px) {
        text-align: center;
        width: 100%;
        padding: 1.5rem 0;
      }
      @media only screen and (min-width: 960px) {
        width: 24.33%;
        padding: 1rem 2.5rem;
        text-align: left;
      }
      & > img {
        max-width: 70px;
      }
    }
    &-links {
      display: flex;
      flex-direction: column;
      text-align: center;
      @media only screen and (min-width: 960px) {
        margin-top: 0rem;
        text-align: left;
        width: 33.33%;
        padding: 1rem 2.5rem;
      }
      @media only screen and (max-width: 960px) {
        width: 100%;
      }
      & > a {
        padding: 0.5rem 0;
        text-decoration: none;
        color: $color--neutral-100;
        &:hover {
          color: $brand-color;
        }
      }
    }
  }
  &-bottom {
    color: $color--neutral-800;
    background: $brand-color;
    padding: 0.4rem 3.55555556rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 0.75rem;
    padding-left: 7.8125vw;
    padding-right: 7.8125vw;
    @media (max-width: 960px) {
      text-align: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding: 1rem;
    }

    &-copy {
      width: 33.33%;
      font-weight: 700;
      @media only screen and (max-width: 960px) {
        width: 100%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 1rem;
      }
    }
    &-love {
      padding: 0 2.5rem;
      font-weight: 700;
      @media only screen and (min-width: 961px) {
        width: 33.33%;
        padding: 0 2.5rem;
        font-weight: 700;
      }
      @media only screen and (max-width: 960px) {
        width: 100%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 1rem;
      }
    }
    &-social {
      & > svg {
        margin-right: 1rem;
        &:hover {
          cursor: pointer;
          color: white;
        }
      }
      @media only screen and (min-width: 961px) {
        width: 33.33%;
        font-size: 1.3rem;
        font-weight: 700;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
      }
      @media only screen and (max-width: 960px) {
        width: 100%;
        font-size: 2rem;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 1rem;
        & > svg {
          margin-right: 1rem;
          &:hover {
            cursor: pointer;
            color: white;
          }
        }
      }
    }
  }
}

main {
  min-height: calc(100vh - 464px);
}
