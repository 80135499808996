@import "../../../styles/base";

.drawing-result {
	list-style-type: none;
	user-select: none;
	touch-action: none;
	cursor: crosshair;
	display: flex;
	flex-direction: column;
	justify-content: center;
	visibility: hidden;
	font-size:38px;
	color: white;
	opacity: 0;
	transition: opacity 0.5s;
	z-index: 1000;
	position: relative;
	background-color: $color--neutral-800;
	&--show {
		visibility: visible;
		opacity: 1;
	}
	&--hide {
		visibility: visible;
		opacity: 0;
	}
	&__score {
		margin: center;
		position: absolute;
		top: 1%;
		right: 12%;
		z-index: 1001;
		color: $color--neutral-800;
		-webkit-user-drag: none;
		user-select: none;
		&-number {
			color: $brand-color;
		}
	}
	&__drawing {
		opacity: 0.9;
		position: absolute;
		left: 0px;
		top: 0px;
		-webkit-user-drag: none;
		user-select: none;
	}
	&__model {
		opacity: 0.3;
		z-index: 200;
		margin: auto;
		-webkit-user-drag: none;
		user-select: none;
	}
}
