@import "../../styles/base";

.auth_form {
  display: flex;
  flex-direction: column;
  .auth_form-input {
    width: 100%;
    margin-bottom: 20px;
  }
}
