@import "../../styles/base";

.account {
  display: flex;
  margin-bottom: 20vh;
  @media (max-width: $breakpoint--large) {
    flex-direction: column;
  }
  @media (min-width: $breakpoint--small) {
    padding: 3rem 3rem 0 0;
  }
  @media (min-width: $breakpoint--xlarge) {
    padding: 5rem 5rem 0 3rem;
  }

  &__sideNav {
    &__unActive {
      text-decoration: none;
      flex-basis: 50px;
      cursor: pointer;
      user-select: none;
      color: white;
    }
    &__active {
      color: $brand-color;
    }
  }
}
