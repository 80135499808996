@import "../../../styles/base";

.canvas {
	-webkit-user-drag: none;
	user-select: none;
	&__area {
		display: flex;
		-webkit-user-drag: none;
		user-select: none;
	}
	&__title {
		font-size: 40px;
		color: red;
	}
	&--left-hand {
		float: left !important;
	}
	&__brush-size {
		display: flex;
		position: absolute;
		top:20px;
		right: 50px;
		&--left{
			right: auto;
			left: 50px;
			.drawing-environment--column &{
				top: auto;
				left: auto;
				right: 50px;
				margin-top: 0!important;
			}
		}
		.drawing-environment--column &{
			top: auto;
			margin-top: 80px;
		}
	}
}
