@import "../../../styles/base";

.canvas-start-overay {
    position: relative;
    z-index: 100;
    user-select: none;
    cursor: crosshair;
    pointer-events: none;
    &--fadein {
        @include fadeIn();
    }
    &--fadeout {
        @include fadeOut();
    }
    @include x-small-screen() {
        float: none;
        margin: auto;
        display: block;
    }
}
