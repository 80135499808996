@import "../../styles/base";

$thumbnailWidth: 400px;
$thumbnailHeight: 250px;
.clubthumbnail {
	width: $thumbnailWidth;
	height: 250px;
	// background-image: url("https://media.discordapp.net/attachments/988891650174615572/988893876641214515/still-life-thumb.png");
	// background-position-x: center;
	// background-position-y: -25px;

	background-color: $brand-color;
	display: inline-block;
	cursor: pointer;
	color: $color--neutral-800;
	margin-right: 30px;
	&:hover {
		.clubthumbnail__overlay {
			background-color: $brand-color;
			opacity: 0.9;
		}
		.clubthumbnail__text {
			color: white;
		}
	}
	&__img {
		position: absolute;
		width: $thumbnailWidth;
		margin-bottom: -$thumbnailHeight;
		height: $thumbnailHeight;
		overflow: hidden;
		padding: 0;
	}
	&__overlay {
		width: 100%;
		height: $thumbnailHeight;
		background-color: white;
		display: inline-block;
		opacity: 0.5;
	}
	&__text {
		display: flex;
		justify-content: center;
		font-weight: 600;
		font-size: 30px;
		letter-spacing: 2px;
		position: relative;
		bottom: 150px;
	}
}
