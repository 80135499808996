@import "../../../styles/base";

.canvas-compete-start-overlay {
    position: relative;
    font-size: 35px;
    justify-self: center;

    &__second-line-text {
        &-counter {
            color: $brand-color;
        }
    }
    &__first-line, &__second-line {
    display: flex;
    justify-content: center;

    }
    &__first-line-start {
        display: inline-block;
        margin-right: 10px;
    }
    &__second-line-text-counter {
        margin: 0 10px;
    }
    &-item--hide {
        visibility: hidden;
    }
    &-item--enlarge {
        transition: width 1.5s, height 1.5s, transform 1.5s, font-size 1.5s;

        transform: translate(80px);
        font-size: 50px;
    }
}
