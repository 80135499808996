@import "../../styles/base";

.security {
  text-align: left;
  padding: 1rem;
  color: $color--neutral-0;
  @media (min-width: $breakpoint--small) {
    float: left;
    width: 55%;
    padding: 0rem 5rem;
  }

  &__change_password {
    display: flex;
    flex-direction: column;
    &__field {
      margin: 1rem;
    }
  }
  .MuiInputBase-root.MuiOutlinedInput-root{
    padding-left:0;

    &:hover {
      border-radius: 0!important;
    }
  }
  input {
    color: #f7f7f7;
    border: 1px solid #f7f7f7;
    margin-bottom: 10px;
    &:hover {
      border: 1px solid #f7f7f7;
    }
  }
}
