@import "../../styles/base";
$desktop-nav-height: 70px;
#logo {
  width: 55px;
  position: relative;
  bottom: 7px;
}
.nav {
  background-color: transparent;
  z-index: 300;
  top: 0;
  width: 100%;
  margin-top: 15px;
  height: $desktop-nav-height;
  background: no-repeat;
  &-links_seperator {
    border-left: 2px solid black;
    margin-right: 10px;
  }
  &-header {
    display: inline;
    &-title {
      display: inline-block;
      float: left;
      position: relative;
      left: 30px;
    }
  }
  &-links {
    display: flex;
    float: right;
    font-size: 1.1rem;
    position: relative;
    align-items: center;
    & > a {
      display: inline-block;
      padding: 0px 1.1rem;
      text-decoration: none;
      color: white;
      margin-right: 5px;
      font-size: 1em;
      line-height: 2.5em;
      &:hover {
        color: $brand-color;
      }
    }
    &-btn {
      height: 70px;
      width: 240px;
      font-weight: 800;
      text-decoration: none;
      letter-spacing: 0.09em;
      outline: none;
      user-select: none;
      display: inline-block;
      text-transform: uppercase;
      font-weight: bold;
      position: relative;
      border: none;
      background: linear-gradient(to right, white 50%, $brand-color 50%);
      background-size: 200% 100%;
      background-position: right bottom;
      transition: all 0.25s ease;
      cursor: pointer;
      &:hover {
        background-position: left bottom;
      }
      &-text {
        text-align: center;
        font-size: 1em;
        line-height: 70px;
        color: white;
        display: block;
        &:hover {
          color: $brand-color;
        }
      }
    }
  }
  &-contact {
    display: none;
    &-icons {
      display: none;
    }
    &-links {
      display: none;
    }
  }
}

.nav > #nav-check {
  display: none;
}
.hamburger {
  display: none;
  margin-left: auto;
  margin-right: 0;
  z-index: 600;
  padding: 25px 15px;
  &-box {
    width: 31px;
    height: 16px;
    display: inline-block;
    position: relative;
    padding: 25px;
  }
  &-inner {
    top: 1px;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -o-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    &:before {
      top: 7px;
      transition: opacity 0.15s 0.4s ease;
    }
    &:after {
      top: 14px;
      -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      -o-transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55),
        -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
  }
}

.hamburger-inner:before,
.hamburger-inner:after {
  content: "";
  display: block;
}

.hamburger-inner,
.hamburger-inner:before,
.hamburger-inner:after {
  width: 31px;
  height: 2px;
  background-color: white;
  border-radius: 0;
  position: absolute;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.15s;
  -o-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
}

@media (max-width: 1100px) {
  .nav {
    &-links {
      position: absolute;
      overflow-y: hidden;
      top: 0;
      right: 0;
      height: 100vh;
      background: white;
      width: 0;
      transition: width 0.2s ease 0s;
      text-align: left;

      & > a {
        display: block;
        width: 100%;
        position: relative;
        color: $color--neutral-800;
        padding: 0.25rem 2rem;
        font-size: 1.3em;
        font-weight: 100;
        &:hover {
          color: $brand-color;
        }
      }
      &-btn {
        margin: 1rem 2rem;
      }
    }
    &-contact {
      display: block;
      margin: 1rem 2rem;
      position: absolute;
      bottom: 25%;
      & > p {
        color: black;
        font-size: 1em;
        font-weight: lighter;
        line-height: 1rem;
      }
      &-icons {
        display: block;
        margin: 2rem 2rem;
        position: absolute;
        bottom: 18%;
        & > :first-child {
          padding-left: 0;
        }
        & > svg {
          font-size: 2em;
          padding: 0 1rem;
          color: $brand-color;
          & > svg:first-child {
            padding-left: 0;
          }
        }
      }
      &-links {
        display: block;
        margin: 2rem 2rem;
        position: absolute;
        bottom: 10%;
        & > :first-child {
          padding-left: 0;
        }
        & > a {
          color: $color--neutral-800;
          text-decoration: none;
          margin-right: 0.5rem;
          font-size: 0.8em;
          &:hover {
            text-decoration: underline;
          }
          & > a:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .nav > #nav-check:not(:checked) ~ .nav-links {
    height: 0vh;
    width: 0%;
    overflow-y: hidden;
    transition: width 1s ease 0s;
  }
  .nav > #nav-check:checked ~ .nav-links {
    display: inline-block;
    width: 95%;
    transition: width 0.2s ease 0s;
    height: 100vh;
    position: fixed;
    overflow-y: hidden;
    z-index: 500;
    padding: 4.625rem 1rem 3rem 0rem;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    outline: 0;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0 auto 0;
    overflow: visible;
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .nav > #nav-check:checked ~ .hamburger .hamburger-inner:after {
    background-color: black;
  }
  .nav > #nav-check:checked ~ .hamburger .hamburger-inner {
    background-color: black;
  }
  .nav > #nav-check:checked ~ .hamburger .hamburger-inner:after {
    -webkit-transform: translate3d(0, -14px, 0) rotate(-270deg);
    transform: translate3d(0, -14px, 0) rotate(-270deg);
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }

  .nav > #nav-check:checked ~ .hamburger .hamburger-inner {
    -webkit-transform: translate3d(0, 7px, 0) rotate(135deg);
    transform: translate3d(0, 7px, 0) rotate(135deg);
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }

  .nav > #nav-check:checked ~ .hamburger .hamburger-inner:before {
    transition-delay: 0s;
    opacity: 0;
  }
}

.profile-nav {
  height: 70px;
  padding-bottom: 40px;
  display: inline-block;
  padding: 0px 1.1rem;
  margin-right: 5px;
  font-size: 1em;
  line-height: 2.5em;
  display: flex;
  @media (max-width: 1100px) {
    height: auto;
  }
  &:hover {
    .profile-list {
      display: block;
    }
  }
}
.user-name {
  display: none;
  font-weight: bold;
  text-transform: capitalize;
}
.user-img {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  bottom: 10px;
  > div {
    width: 55px;
    height: 55px;
  }
  &.medium {
    padding-left: 15px;
    padding-top: 7px;
    > div {
      width: 75px;
      height: 75px;
    }
  }
}
.profile-list {
  display: none;
  height: 20rem;
  width: 60%;
  position: absolute;
  color: $color--neutral-100;
  z-index: 100;
  top: 70px;
  right: 246px;
  background-color: #151515ef;
  border-radius: 8px;
  box-shadow: -1px 0px 10px -1px #000000b5;

  .user-info {
    display: flex;
    border-bottom: 1px solid #f43c001c;
    cursor: default;
  }
  .user-details {
    text-align: initial;
    font-size: 14px;
    ul {
      padding: 10px 0;
      li {
        height: 40px;
        margin-top: -10px;
      }
    }
  }
  &:after {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    z-index: 100000000;
    top: -8px;
    right: 54px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8px solid #f43c001c;
  }
}
.profile-links {
  ul {
    margin: 0;
    padding: 0;

    li {
      text-align: left;
      height: 40px;
      white-space: nowrap;

      transition: opacity 0.2s ease-in-out;
      padding-left: 20px;
      &:last-child {
        border-top: 1px solid #f43c001c;
        padding-top: 6px;
      }
      a {
        color: $color--neutral-100;
        text-decoration: none;
        transition: color 0.15s ease-in-out;
      }
      &:hover {
        a {
          color: $brand-color;
        }
      }
    }
  }
}
.arrow {
  border: solid black;
  border-radius: 1px;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin: 0 0 2px 10px;
  transition: transform 0.5s ease-in-out;

  &.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  &.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

@media (max-width: 1100px) {
  .profile-nav {
    &:hover {
      .profile-list {
        display: none;
      }
    }
    .profile-list.open {
      display: block;
    }
  }
  .profile-list {
    position: relative;
    top: 40px;
    right: 0;
    left: 44px;
    background-color: white;
    height: 100%;
    box-shadow: none;
    @media (max-width: 1100px) {
      position: initial;
      left: initial;
      top: initial;
      height: auto;
      width: 100%;
    }
    &:after {
      display: none;
    }
  }
  .user-img.medium {
    display: none;
  }
  .user-dropdown-wrapper{
    display: flex;
    flex-direction: column;
  }
  .user-name {
    display: block;
    position: absolute;
    left: 100px;
    top: 26px;
    @media (max-width: 1100px) {
      position: initial;
      left: initial;
      top: initial;
    }
  }
  .user-info {
    border-bottom: none !important;
  }
  .user-details {
    display: none;
  }
  .profile-links {
    ul {
      padding-bottom: 10px;
      li {
        text-align: left;
        height: 35px;
        padding-left: 20px;
        padding-bottom: 15px;
        &:last-child {
          border: none;
          padding-top: 0;
        }
        a {
          color: $color--neutral-900;
        }
      }
    }
  }
}
